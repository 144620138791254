<template>
    <div class="contianer" style="margin: 5rem; font-family: Prompt;">
        <v-row class="p-5">
            <h1 style="color:black; margin: 0.5rem;">จัดการผู้ดูแลและหลักสูตร</h1>
            <v-spacer></v-spacer>
            <!-- <v-btn
                color="#FFA41A"
                style="margin-top: 1rem; margin-right: 0.5rem;"
                class="white--text"
                @click=" openCreateModal.createAdmin = true"
                >
                เพิ่มผู้ดูเเล
            </v-btn>
            <v-btn
                color="#745CA8"
                style="margin-top: 1rem; margin-right: 0.5rem;"
                class="white--text"
                @click=" openCreateModal.createCourse = true"
                >
                เพิ่มหลักสูตร
            </v-btn>              -->
        </v-row>
        <v-row>
            <h2 style="color:black; margin: 0.5rem;">ขอบเขตรับผิดชอบ</h2>
        </v-row>
        <v-row>
            <v-col v-if="this.isAdminFac">
                <v-select 
                    v-model="selectedFaculty"
                    :items="ListAllFaculty"
                    item-text="title"
                    label="Please Select Faculty"
                    dense
                    solo
                    disabled
                ></v-select>
            </v-col>
            <v-col v-if="this.isAdminCMU">
                <v-select 
                    v-model="selectedFaculty"
                    :items="ListAllFaculty"
                    item-text="title"
                    label="Please Select Faculty"
                    dense
                    solo
                ></v-select>
            </v-col>
            <v-spacer></v-spacer>
            <v-col></v-col>
            
        </v-row>
    
        <v-card style=" border-radius: 10px!important; margin-top: 2rem;">
            <v-tabs
                background-color="#fff"
                color="#745CA8"
                height="80px"
                active-class="active"
                >
                <v-tab href="#admin" style="font-size: 20px; font-weight: 600; margin-left: 2rem;">ผู้ดูแล</v-tab>
                <v-tab href="#course" style="font-size: 20px; font-weight: 600; ">หลักสูตร</v-tab>
                <v-spacer></v-spacer>
                <v-btn
                    color="#FFA41A"
                    style="margin-top: 1rem; margin-right: 0.5rem;"
                    class="white--text"
                    @click=" openCreateModal.createAdmin = true"
                    >
                    เพิ่มผู้ดูเเล
                </v-btn>
                <v-btn
                    v-if="selectedFaculty.includes('CMU') === false && selectedFaculty !== ''"
                    color="#745CA8"
                    style="margin-top: 1rem; margin-right: 0.5rem;"
                    class="white--text"
                    @click=" openCreateModal.createCourse = true"
                    >
                    เพิ่มหลักสูตร
                </v-btn> 
                <v-tab-item value="admin" style="border-top: 1px solid #C4C4C4 !important;" >
                    <AdminManage :adminList=adminList @handleDeleteAdmin="handleRemoveAdmin($event)"></AdminManage>
                </v-tab-item>
                <v-tab-item value="course"  style="border-top: 1px solid #C4C4C4 !important;">
                    <CourseManage :courseData="courseList"  @modalEdit="handleEditCourseAdmin"  @modalRemove="handleEditCourseAdmin" @onCreateCourse="handleCreateCourse" ></CourseManage>
                </v-tab-item>
            </v-tabs>
            
        </v-card>
        <CreateAdminModal :selectOption="selectedFaculty" :adminCMU="isAdminCMU" :adminFac="isAdminFac" :courseData="courseList" @modalSubmit="onModalCreateAdminSubmit" :openModal="openCreateModal.createAdmin" @modalClose="openCreateModal.createAdmin = false" @handleAdd="onAddAdmin" style="display: none" v-bind:key="`create-admin-modal`"/>
        <CreateCourseModal :courseData="[]" @modalSubmit="onModalCreateCourseSubmit" :openModal="openCreateModal.createCourse" @modalClose="openCreateModal.createCourse = false" style="display: none" v-bind:key="`create-course-modal`"/>
        <RemoveAdminModal :courseData="handleAdminData['course-subjection']" @modalSubmit="onModalRemoveAdminSubmit" :openModal="openCreateModal.removeAdmin" @modalClose="openCreateModal.removeAdmin = false" style="display: none" v-bind:key="`create-cmu-exam-modal`"/>
        <EditAdminCourseModal :courseData="handleCourseData" @modalSubmit="onModalRemoveAdminSubmit" :openModal="openCreateModal.editCourseAdmin" @modalClose="openCreateModal.editCourseAdmin = false" @submitEdit="onModaEditCourseSubmit" style="display: none" v-bind:key="`create-cmu-exam-modal`"/>
        <RemoveCourseAdminModal :courseData="handleAdminData['course-subjection']" @modalSubmit="onModalRemoveAdminSubmit" :openModal="openCreateModal.removeAdmin" @modalClose="openCreateModal.removeAdmin = false" style="display: none" v-bind:key="`create-cmu-exam-modal`"/>

    </div>

</template>
<script>
//import api 
import apiGatewayCpmService from "@/services/apiGatewayService";
// const config = require(`@/config`)
import Swal from "sweetalert2"

import CreateAdminModal from '../../components/AdminComponents/CreateAdminModal'
import CreateCourseModal from '../../components/AdminComponents/CreateCourseModal'
import RemoveAdminModal from '../../components/AdminComponents/RemoveAdminModal'
import EditAdminCourseModal from "../../components/AdminComponents/EditAdminCourseModal.vue";
import AdminManage from '../../components/AdminComponents/AdminManagement'
import CourseManage from '../../components/AdminComponents/CourseManagement'
import RemoveCourseAdminModal from '../../components/AdminComponents/RemoveCourseAdminModal.vue'

export default {
    name : 'AdminManagement' ,
    components :{
        AdminManage , 
        CourseManage ,
        CreateAdminModal , 
        CreateCourseModal , 
        RemoveAdminModal , 
        EditAdminCourseModal,
        RemoveCourseAdminModal
    },
    computed: {
      userData: {
          get () {
            return this.$store.state.userData
          },
          set () {
            this.$store.commit('setUserData')
          }
      }
    },
    data() {
        return {
            openCreateModal : {
                createAdmin  : false , 
                createCourse : false  , 
                removeAdmin  : false , 
                editCourseAdmin : false
            },
            courseList : [] , 
            ListAllFaculty : [],
            isAdminCMU : false ,
            isAdminFac : false ,
            selectedFaculty : '' ,
            adminList : [] , 
            handleAdminData : [],
            handleCourseData : []
           
        }
    },
    created () { 
        // const grantAdmin = await apiGatewayCpmService.getGrantAdminCMU(localStorage.getItem("accessToken"));
        // const hasFaculty = grantAdmin.grantOnCourses.find(element => { 
        //         if(element.includes('fac')){
        //             this.isAdminFac = true;
        //             return element ;
        //         }
        //     }
        // )
        this.getAdminCMU()
    },
    watch :{
        async selectedFaculty(newVal){
            
            Swal.showLoading()  
            const facultyObj = this.ListAllFaculty.find(element => { 
                if(element.title === newVal ){
                    return element ;
                }
                
            });
            
            const adminList = await apiGatewayCpmService.GetAdminListManage(localStorage.getItem("accessToken") , `?faculty=fac-${facultyObj.facultyNumber}`);
            this.adminList = adminList
            
            this.getCourseAdmin()
            // console.log("Faculty:" , facultyObj.facultyNumber , newVal ,this.adminList  )
            Swal.close()
        }
    },
    methods: {
        onModalCreateAdminSubmit(val){
           console.log("Create add" , val)
        },
        async onModalCreateCourseSubmit( name , mappingNumber){
            Swal.showLoading()
            const facultyObj = this.ListAllFaculty.find(element => { 
                if(element.title === this.selectedFaculty ){
                    return element ;
                }
                
            });
            const grantAdmin = await apiGatewayCpmService.getGrantAdminCMU(localStorage.getItem("accessToken"));
            let formData = {
                "email":grantAdmin.email,
                "prefixCourseNumber": mappingNumber,
                "facultyNumber": facultyObj.facultyNumber,
                "title": name
            }
            const response = await apiGatewayCpmService.CreateMappingCourse(localStorage.getItem("accessToken") , formData)
            Swal.close()
            if(response.status){
                this.openCreateModal.createCourse = false
                Swal.fire({
                    icon: 'success',
                    title: 'เพิ่มหลักสูตรสำเร็จ',
                }) 
                this.getCourseAdmin()
            }else{
                Swal.fire({
                    icon: 'error',
                    title: 'เพิ่มหลักสูตรไม่สำเร็จ',
                })
            }
        },
        async onModalRemoveAdminSubmit(val){           
            for(let obj of val){
                this.handleAdminData["course-subjection"] = this.handleAdminData["course-subjection"].filter((e) => e.prefixCourseNumber !== obj)
            }
            let courseUpdate = []
            for(let obj of this.handleAdminData["course-subjection"]){
                courseUpdate.push(obj.prefixCourseNumber)
            }
            let formData = {
                "mode":"update",
                "grantOnCourses": courseUpdate ,
                "isApprove": true,
                "permission":"course"
            }
            const response = await apiGatewayCpmService.updateGrantOnCourse(localStorage.getItem("accessToken") , this.handleAdminData.account_id , formData )
            console.log("reponse" , response)
            if(response.status){
                Swal.fire({
                    icon: 'success',
                    title: 'เเก้ไขหลักสูตรของผู้ดูเเลสำเร็จ',
                }) 
                this.getAdminDetail()                    
            }else{
                Swal.fire({
                    icon: 'error',
                    title: response.message,
                })
            }
        },
        handleRemoveAdmin(val){
            this.openCreateModal.removeAdmin = true
            this.handleAdminData = val
        },
        handleEditCourseAdmin(course){
            console.log("Edit Course")
            this.openCreateModal.editCourseAdmin = true
            this.handleCourseData = course
            // console.log("handleCourseData" , this.handleCourseData )
        },
        handleRemoveCourseAdmin(course){
            console.log(course)
            this.openCreateModal
        },
        async onModaEditCourseSubmit(courseTitle , courseNumber){
            Swal.showLoading()
           
            const grantAdmin = await apiGatewayCpmService.getGrantAdminCMU(localStorage.getItem("accessToken"));
            // console.log("grantAdmin : " , grantAdmin.email)

            let formData = {
                "email":grantAdmin.email,
                "prefixCourseNumber": courseNumber,
                "title": courseTitle
            }
            console.log("update form: " , formData)

            const response = await apiGatewayCpmService.UpdateMappingCourse(localStorage.getItem("accessToken") , formData) //รออัพเดท
            if(response.status){
                Swal.close()
                this.openCreateModal.editCourseAdmin = false
                Swal.fire({
                    icon: 'success',
                    title: 'แก้ไขหลักสูตรสำเร็จ',
                }) 
                
            }else{
                Swal.fire({
                    icon: 'error',
                    title: 'เเก้ไขหลักสูตรไม่สำเร็จ',
                })
            }
        },
        
        async onAddAdmin( adminEmail , selected , selectOption){
            const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/            
            const courseSelect = JSON.parse(JSON.stringify(selected))
            console.log("Submit :> " ,  adminEmail , courseSelect , selectOption)
            if(pattern.test(adminEmail) && (selectOption === 0 || selectOption === 1 || courseSelect.length > 0)){
                const haveUser = await apiGatewayCpmService.GetValidateUserInstructor(localStorage.getItem("accessToken") , adminEmail )
                console.log("haveUser", haveUser)
                if(haveUser.status){
                    let formData = {};
                    if(selectOption === 0){
                        formData = {
                            "email" : adminEmail,
                            "grantOnCourses" : "CMU"
                           

                        }
                    }
                    else if(selectOption === 1){
                        // console.log("grantAdmin :>" , this.selectedFaculty , this.ListAllFaculty)
                        const facultyObj = this.ListAllFaculty.find(element => { 
                            if(element.title === this.selectedFaculty ){
                                return element ;
                            }
                            
                        });
                        formData = {
                            "email" : adminEmail,
                            "grantOnCourses" : "fac-"+String(facultyObj.facultyNumber)
                        }
                    }else{
                        formData = {
                            "email" : adminEmail,
                            "grantOnCourses" : courseSelect 
                        }
                    }
                    Swal.showLoading()    
                    const response = await apiGatewayCpmService.CreateAdminCourse(localStorage.getItem("accessToken") , formData )
                    if(response.status){
                        Swal.fire({
                            icon: 'success',
                            title: 'เพิ่มผู้ดูเเลสำเร็จ',
                        }) 
                        this.getAdminDetail()                    
                    }else{
                        this.alertError(response.message)
                        Swal.close()
                    }

                }else{
                    Swal.fire({
                        icon: 'error',
                        title: 'เพิ่มผู้ดูเเลไม่สำเร็จ',
                    })            
                }
                
            }
        },
        async handleCreateCourse(title , mappingNumber){
            console.log("handleCreateCourse:>" ,title , mappingNumber )
        },
        
        async getAdminDetail(){
            let query = ``;
            const grantAdmin = await apiGatewayCpmService.getGrantAdminCMU(localStorage.getItem("accessToken"));
            const grantAdminFaculty = grantAdmin.grantOnCourses.find(element => { 
                if(element.includes('fac')){
                    this.isAdminFac = true;
                    return element ;
                }
                
            });
            if(this.isAdminFac){
                query = `?faculty=`+grantAdminFaculty ;
                
            }

            const adminList = await apiGatewayCpmService.GetAdminListManage(localStorage.getItem("accessToken") , query);
            
            this.adminList = adminList
            Swal.close()    
        },
       
        async getAdminCMU(){
            const grantAdmin = await apiGatewayCpmService.getGrantAdminCMU(localStorage.getItem("accessToken"));
            if(grantAdmin){
                if(grantAdmin.grantOnCourses.includes("CMU")){
                    this.isAdminCMU = true ; 
                    const allFaculty = await apiGatewayCpmService.getAllFaculty(localStorage.getItem("accessToken"));
                    if(allFaculty){
                        delete allFaculty.status ;
                        allFaculty.splice(0,0 , { "title" : "CMU"}),
                        this.selectedFaculty = 'CMU' ;
                        this.ListAllFaculty = allFaculty ; 
                        
                    }
                }else{
                    this.isAdminCMU = false ; 
                    const hasFaculty = grantAdmin.grantOnCourses.find(element => { 
                                if(element.includes('fac')){
                                    this.isAdminFac = true;
                                    return element ;
                                }
                            }
                        )
                    if(this.isAdminFac){
                        const allFaculty = await apiGatewayCpmService.getAllFaculty(localStorage.getItem("accessToken"));
                        delete allFaculty.status ;
                        this.selectedFaculty = await String(allFaculty.find((el)=>{ return el.facultyNumber === String(hasFaculty.replace('fac-', ''))}).title)
                        this.ListAllFaculty = allFaculty ;
                    }
                }
                this.getCourseAdmin()
                this.getAdminDetail()  
            }
            Swal.showLoading()  
        },
        async getCourseAdmin(){
            this.courseList = []
            if(this.isAdminCMU && this.selectedFaculty == ""){
                console.log("isAdminCMU:>" )
                this.courseList = []
            }else if(this.selectedFaculty){
                console.log("isAdminFac:>" )
                const facultyObj = this.ListAllFaculty.find(element => { 
                    if(element.title === this.selectedFaculty ){
                        return element ;
                    }
                    
                });
                const query = `?faculty=fac-${facultyObj.facultyNumber}`
                const response = await apiGatewayCpmService.getAdminByCourse(localStorage.getItem("accessToken") , query)
                if(response.status){
                    delete response.status;
                    this.courseList = response
                }
            }else{
                // console.log("isAdminCourse")
                const grantAdmin = await apiGatewayCpmService.getGrantAdminCMU(localStorage.getItem("accessToken"));
                // console.log("grantAdmin:>" , grantAdmin.grantOnCourses)
                for(let obj of grantAdmin.grantOnCourses){
                    console.log(obj)
                    const response = await apiGatewayCpmService.getAdminByCourse(localStorage.getItem("accessToken") , `?faculty=${obj}`)
                    // console.log("response",response)
                    if(response.status){
                        delete response.status;
                        this.courseList.push(response[0])
                    }
                }
                // console.log("this.courseList" ,  this.courseList)
                

            }
            
        }
      
    },
}
</script>